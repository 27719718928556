export const couponStatusColors = {
    Available: 'green',
    Cancelled: 'red',
    Claimed: 'gold'
}

export const voucherStatusColors = {
    pending: 'gold',
    not_redeemed: 'green',
    redeemed: 'blue',
    failed: 'red',
    not_available: 'rgba(214, 214, 214, 1)'
}

export const transactionStatusColors = {
    pending: 'gold',
    not_redeemed: 'green',
    redeemed: 'blue',
    failed: 'red',
    not_available: 'rgba(214, 214, 214, 1)'
}

export const registrationStatusColors = {
    pending: 'gold',
    complete: 'green',
    expired: 'blue',
}

export const title = [
    { id: "/admin/dashboard", name: "OTT 4 Me Dashboard" },
    { id: "/admin/voucher", name: "OTT Vouchers" },
    { id: "/admin/coupons", name: "OTT Coupons" },
    { id: "/admin/transactions", name: "OTT Transactions" },
    { id: "/admin/payout/providers", name: "OTT Payout Providers" },
    { id: "/admin/custom/providers", name: "OTT Custom Providers" },
    { id: "/admin/second_chance/competitions", name: "Competitions" },
    { id: "/admin/second_chance/competitions/entries/:competitionID", name: "Competition Entries" },
    { id: "/admin/second_chance/surveys", name: "Surveys" },
    { id: "/admin/second_chance/sponsors", name: "Sponsors" },
    { id: "/admin/second_chance/service_providers", name: "Service Providers" },
    { id: "/admin/second_chance/issuers", name: "Issuers" },
    { id: "/admin/users/user", name: "Users" },
    { id: "/admin/users/admins", name: "Admin Users" },
    { id: "/admin/roles", name: "Roles" },
    { id: "/admin/notifications/view", name: "Notifications Dashboard" },
    { id: "/admin/notifications/create", name: "Create Notification" },
    { id: "/admin/notifications/edit", name: "Edit Notification" },
    { id: "/admin/settings/designer", name: "Page Designer" },
    { id: "/admin/settings/library", name: "Media Library" },
    { id: "/admin/settings/health", name: "Health Checks" },
    { id: "/admin/settings/exports", name: "Exports" },
    { id: "/admin/settings_main", name: "Settings" }
];